import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useI18nContext} from '@teladoc/pulse/ui/Context/i18n';
import {useTransLoader} from '@teladoc/fe-i18n';
import StorageUtils from '@livongo/utilities/system/storage';
import Loader from '../../loader/Loader';
import TeladocSSOForm from '../../teladoc-sso-form/TeladocSSOForm';
import OneAppMigrationAPI from '../../oneAppMigration/oneAppMigration-api';
import CommonUtils from '../../common/utilities/common-utils';
import MixpanelUtils from '../../common/utilities/mix-panel';
import {IS_PROD, KEYCLOAK_URI} from '../../config';

const VALUES = {
    0: 'UNKNOWN',
    1: 'CCM ',
    2: 'CCM_MYSTRENGTH_2_3',
    3: 'MYSTRENGTH_2_3',
};

const STATUS = {
    0: 'UNKNOWN',
    1: 'NOT_STARTED',
    2: 'IN_PROGRESS',
    3: 'ACCOUNT_CREATED',
    4: 'COMPLETED',
};

const Keycloak = () => {
    const {t} = useTransLoader('user');
    const {selectedLang} = useI18nContext();
    const {livongoUUID} = useSelector(state => state.user);
    const mobileUUID = StorageUtils.get({key: 'mobileUUID'});
    const keycloakUri = CommonUtils.getCookie({key: KEYCLOAK_URI});
    const mobileClientName = StorageUtils.get({key: 'mobile_clientName'});
    const mobileLocale = StorageUtils.get({key: 'mobile_locale'});
    const mobileSessionUuid = StorageUtils.get({key: 'mobile_sessionUuid'});
    const sessionUuid = CommonUtils.getSessionUUID();
    const ccmAuthToken = CommonUtils.getCookie({key: 'access_token'});
    const [teladocSSOData, setTeladocSSOData] = useState(null);
    const [showLoader, setShowLoader] = useState(true);
    const host = window.location.origin;
    const redirectUrl = `${host}/dashboard`;

    const fetchData = async () => {
        // determine currentMigrationStatus
        const response = await OneAppMigrationAPI.getOneAppMigration();
        const migrationInfoResponse = response.getMigrationInfo();
        const benefitsOffered = migrationInfoResponse?.getBenefitsOffered();
        const migrationStatus =
            migrationInfoResponse?.getCurrentMigrationStatus();
        const currentMigrationStatus = STATUS[migrationStatus];
        // Determine the value of 'uri'
        let uri;

        if (currentMigrationStatus === 'COMPLETED') {
            // If migration is completed, set to a specific URL
            uri = `https://member-morpheus-cm.public.qa.tdoctest.com/auth/keycloakopenid`;
        } else {
            // Check environment and determine URL
            uri =
                !IS_PROD && keycloakUri
                    ? CommonUtils.generateNewUrl(keycloakUri)
                    : process.env.MIGRATION_URL;
        }
        // Use the determined URI
        console.log('Redirecting to URI:', uri);

        try {
            if (mobileUUID) {
                // Based on mobileUUID, which corresponds to the livongoUUID in the context of the mobile workflow.
                const initialData = {
                    uri,
                    parameters: {
                        ccmUuid: mobileUUID,
                        clientName: mobileClientName,
                        fromMobile: 'true',
                        locale: mobileLocale,
                        sessionUuid: mobileSessionUuid,
                    },
                };

                // Set the initial values of the form inputs and update the state
                setTeladocSSOData(initialData);
                MixpanelUtils.track({
                    event: 'mobileuser.isredirectedto.membermigrationendpoint',
                    properties: {
                        'Session UUID': mobileSessionUuid,
                    },
                });
                setShowLoader(!teladocSSOData);

                // After the SSO form submission is successful, remove the 'mobileUUID' & keycloak_uri cookie
                StorageUtils.remove({key: 'mobileUUID'});
                StorageUtils.remove({key: 'mobile_clientName'});
                StorageUtils.remove({key: 'mobile_locale'});
                StorageUtils.remove({key: 'mobile_sessionUuid'});
                CommonUtils.removeCookie(KEYCLOAK_URI);
            } else {
                const clientName = VALUES[benefitsOffered];

                // Update the values of the form inputs and update the state
                setTeladocSSOData({
                    uri,
                    parameters: {
                        ccmUuid: livongoUUID,
                        clientName,
                        fromMobile: 'false',
                        locale: selectedLang,
                        redirectUrl,
                        sessionUuid,
                        ccmAuthToken,
                    },
                });
                MixpanelUtils.track({
                    event: 'webuser.isredirectedto.membermigrationendpoint',
                    properties: {
                        'Session UUID': sessionUuid,
                    },
                });

                // Hide the loader after data is fetched and processed
                setShowLoader(false);
                CommonUtils.removeCookie(KEYCLOAK_URI);
            }
        } catch (error) {
            MixpanelUtils.track({event: 'encountered.error'});

            return error;
        }
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {showLoader && (
                <Loader loadingText={t('transitionScreen.loadingText')} />
            )}
            {teladocSSOData && <TeladocSSOForm data={teladocSSOData} />}
        </>
    );
};

export default Keycloak;
